
import '@/assets/styles/imports/login.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'
import api from '@/api'
import storage from '@/storage'

@Component
export default class TheLogin extends Vue {
  @Prop({ type: Object, default: {}}) readonly prevPage!: Route

  email = ''
  requiredEmail = false
  password = ''
  requiredPassword = false
  remember = false

  async sendForm () {
    if (!this.email.length) return this.setRequiredEmail()
    if (!this.password.length) return this.setRequiredPassword()
    const { login, message } = await api.authorize.login(this.email, this.password, this.remember)
    if (!login && message) return this.openErrorModal(message)
    await api.authorize.getUser()
    await this.redirect()
  }

  setRequiredEmail () {
    this.requiredEmail = true
  }

  setRequiredPassword () {
    this.requiredPassword = true
  }

  redirect () {
    const storagePrevPage = storage.pageFromLogin
    if (storagePrevPage) {
      storage.pageFromLogin = null
      return this.$router.push(storagePrevPage.fullPath)
    }
    if (!this.prevPage.name) return this.$router.push({
      name: 'Home',
      params: { lang: this.$route.params.lang }
    })
    if (this.prevPage.name === 'Prices' && storage.lastVehicle) return this.$router.push({
      name: 'Order',
      params: { lang: this.$route.params.lang }
    })
    if (this.prevPage.name === 'Stock') return this.$router.push({
      name: 'Stock',
      params: { id: this.prevPage.params.id, lang: this.$route.params.lang }
    })
    this.$router.push({ name: 'History', params: { lang: this.$route.params.lang }})
  }

  openErrorModal (text: string) {
    this.$emit('error', text)
  }
}
