
import { Component, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'
import TheLogin from '@/components/login/TheLogin.vue'
import ModalNote from '@/components/_modal/ModalNote.vue'
import storage from '@/storage'

@Component({
  beforeRouteEnter (to: Route, from: Route, next: Function) {
    next((vm: LoginPage) => {
      if (from.name) vm.prevPage = from
    })
  },
  beforeRouteLeave (to: Route, from: Route, next: Function) {
    storage.pageFromLogin = null
    next()
  },
  components: {
    TheLogin,
    ModalNote
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class LoginPage extends Vue {
  prevPage: Route | {} = {}
  modalErrorText = ''

  openModal (text: string) {
    this.modalErrorText = text
  }

  closeModal () {
    this.modalErrorText = ''
  }
}
